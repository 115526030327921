// KeyboardEvent.key
// 'AltGraph' will let e.altKey and e.ctrlKey become true (Not tested)
export const MODIFIER_KEY_EVENT_KEYS = [
  'Control',
  'Alt',
  'Shift',
  'Meta',
  'AltGraph',
];

// KeyboardEvent.code
// Digits, Letters, Symbols
export const MAIN_KEY_EVENT_CODES = [
  'Backquote',
  'Digit1',
  'Digit2',
  'Digit3',
  'Digit4',
  'Digit5',
  'Digit6',
  'Digit7',
  'Digit8',
  'Digit9',
  'Digit0',
  'Minus',
  'Equal',
  'KeyQ',
  'KeyW',
  'KeyE',
  'KeyR',
  'KeyT',
  'KeyY',
  'KeyU',
  'KeyI',
  'KeyO',
  'KeyP',
  'BracketLeft',
  'BracketRight',
  'Backslash',
  'KeyA',
  'KeyS',
  'KeyD',
  'KeyF',
  'KeyG',
  'KeyH',
  'KeyJ',
  'KeyK',
  'KeyL',
  'Semicolon',
  'Quote',
  'KeyZ',
  'KeyX',
  'KeyC',
  'KeyV',
  'KeyB',
  'KeyN',
  'KeyM',
  'Comma',
  'Period',
  'Slash',
];
export const ARROW_KEY_EVENT_CODES = [
  'ArrowUp',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
];
export const SPACE_KEY_EVENT_CODES = ['Backspace', 'Space', 'Enter'];
